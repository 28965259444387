<template>
  <div>
    <flixDashboard>
      <template v-slot:content>
        <div class="flix-container">
          <loggingTable />
        </div>
      </template>
    </flixDashboard>
  </div>
</template>
<script>
export default {
  name: 'DashboardLogs',
  components: {
    loggingTable () { return import('@/components/logs/') }
  },
  props: {},
  data() {
    return {}
  },
  computed: {},
  mounted() {},
  methods: {}
}
</script>
<style lang="sass" scoped></style>
